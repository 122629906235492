import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Stack,
  ListItem,
  ListItemText,
  List,
  Modal,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import AddDocumentModal from "./AddDocumentModal";
import { CloseFullscreen } from "@mui/icons-material";
import api from "../Constants/Api";
import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

export default function ListDocument({
  docid,
  setrefresh,
  refresh,
  customerid,
  data,
}) {
  const [file, setFile] = useState([]);
  const [openmodal, setOpenModal] = useState(false);
  const [error, seterror] = useState("");
  const [showSnackbar, setshowSnackbar] = useState(false);
  const [message, setmessage] = useState("");
  const [mode, setmode] = useState("error");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deletedocid, setdeletedocid] = useState("");
  const [deletemodal, setDeleteModal] = useState(false);
  const closeModal = () => setOpenModal(false);

  console.log("=-=-=-=-=-=-data", data.tracking_status);

  useEffect(() => {
    if (refresh) {
      handleListDoc();
    }
  }, [refresh]);

  useEffect(() => {
    handleListDoc();
  }, [docid]);

  const handleListDoc = () => {
    const params = new URLSearchParams();
    params.append("customer_service_id", docid);

    const url = `${api.get_service_document_for_web}?${params.toString()}`;

    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          setFile(result?.data);
          setrefresh(false);
        } else {
          seterror(result.message);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {});
  };

  const handleDelete = (id) => {
    setDeleteLoading(true);
    const formdata = new FormData();
    formdata.append("customer_service_id", docid);
    formdata.append("file_id", deletedocid);
    const requestOptions = {
      method: "POST",
      redirect: "follow",
      body: formdata,
    };
    fetch(api.delete_service_document_for_web, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          file.length = 0;

          setshowSnackbar(true);
          setmessage(result?.message);
          setmode("success");
          setDeleteModal(false);
          handleListDoc();
        } else {
          // seterror(result.message);
          console.log("error", result.message);
          setshowSnackbar(true);
          setmessage(result?.message);
          setmode("error");
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setDeleteLoading(false);
      });
  };
  const handleDeleteCloseModal = () => {
    setDeleteModal(false);
  };

  const handleDelteOpenModal = (docid) => {
    setDeleteModal(true);
    setdeletedocid(docid);
  };
  return (
    <Box>
      <Stack
        sx={{
          flexDirection: "row",
          width: "100%",
          paddingTop: 1,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            flex: 1,
            marginLeft: 10,
            color: "#f90000",
          }}
        >
          Document
        </Typography>
        <Button
          disabled={
            refresh || data?.tracking_status === "completed" ? true : false
          }
          variant="contained"
          color="secondary"
          sx={{
            textTransform: "none",
            backgroundColor: "#f90000",
            color: "white",
            alignItems: "flex-end",
            fontSize: 12,
            marginRight: 0.5,
            textAlign: "center",
            "&:hover": {
              backgroundColor: "#ccc",

              boxShadow: "none",
            },
          }}
          onClick={() => {
            setOpenModal(true);
          }}
        >
          Add Document
        </Button>
      </Stack>

      <Box
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          height: "424px",
          overflowY: "auto", // Enables vertical scrolling only
          overflowX: "hidden", // Hides horizontal scrollbar
          scrollbarWidth: "thin", // For Firefox, makes the scrollbar thinner
          scrollbarColor: "#888 #f1f1f1", // Customize scrollbar color for Firefox
        }}
      >
        <List>
          {file &&
            file.map((doc, index) => (
              <>
                <Box sx={{ padding: 0.5 }} key={index}>
                  <ListItem
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: 2,
                      boxShadow: 1,
                      alignItems: "center",
                    }}
                    secondaryAction={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        {doc?.uploaded_by !== "Company" ? (
                          <IconButton
                            disabled={
                              data?.tracking_status === "completed"
                                ? true
                                : false
                            }
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleDelteOpenModal(doc?.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        ) : (
                          <div style={{ height: 45 }}></div>
                        )}
                        <Typography
                          sx={{ marginTop: 0.8, fontSize: 11 }}
                          variant="body2"
                          color="text.secondary"
                        >
                          {doc?.created_at}
                        </Typography>
                      </Box>
                    }
                  >
                    <ListItemText
                      primary={
                        <Link
                          to={doc?.url}
                          target="blank"
                          variant="body2"
                          sx={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {doc.file_name}
                        </Link>
                      }
                      secondary={
                        <>
                          <Typography variant="body2" color="text.secondary">
                            Uploaded by: {doc?.uploaded_by}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Step Name: {doc?.step || "-"}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                </Box>
              </>
            ))}
        </List>

        {file?.length === 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90%",
            }}
          >
            <Typography color={"#ccc"}>{error}</Typography>
          </Box>
        )}
      </Box>

      <Dialog open={deletemodal} onClose={handleDeleteCloseModal}>
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this document?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteCloseModal}
            sx={{ color: "#f90000", fontSize: "bold", textTransform: "none" }}
          >
            No
          </Button>

          <LoadingButton
            loading={deleteLoading}
            variant="contained"
            color="secondary"
            sx={{
              mr: 1,
              textTransform: "none",
              backgroundColor: "#f90000",
              color: "white",
              "&:hover": {
                backgroundColor: "#ccc",
                boxShadow: "none",
              },
            }}
            onClick={handleDelete}
          >
            Yes
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Modal open={openmodal} onClose={closeModal}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh", // Full viewport height for vertical centering
            bgcolor: "rgba(0, 0, 0, 0.5)", // Transparent background for overlay effect
          }}
        >
          <Box
            sx={{
              width: 400, // Set a fixed width for the modal
              maxHeight: "90vh", // Maximum height to avoid overflow on small screens
              bgcolor: "#fff",
              boxShadow: 24,
              overflow: "auto",
              p: 2,
              borderRadius: 2,
              border: "2px solid",
              borderColor: "primary.main",
            }}
          >
            <AddDocumentModal
              customerid={customerid}
              onClose={closeModal}
              adddocid={docid}
              data={data}
              getdata={() => {
                closeModal();
                handleListDoc();
              }}
              refresh={handleListDoc}
            />
          </Box>
        </Box>
      </Modal>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => {
          setshowSnackbar(false);
        }}
      >
        <Alert
          onClose={() => {
            setshowSnackbar(false);
          }}
          severity={mode}
          sx={{
            width: "100%",
            color: "black",
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
