import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import api from "../Constants/Api";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";

export default function Messagebox({
  messaegeid,
  messagedata,
  customerid,
  refresh,
  setrefresh,
}) {
  const [data, setdata] = useState([]);
  const [error, seterror] = useState([]);
  const [message, setMessage] = useState("");
  const [openCommunication, setopenCommunication] = useState(false);
  const [loading, setloading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    if (refresh) {
      handleMessage();
    }
  }, [refresh]);

  useEffect(() => {
    handleMessage();
  }, [messaegeid]);

  const handleMessage = () => {
    const params = new URLSearchParams();
    params.append("customer_service_id", messaegeid);
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    const url = `${api.get_service_message}?${params.toString()}`;
    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          setdata(result?.data);
          setrefresh(false);
        } else {
          seterror(result.message);
          console.log("error", result.message);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {});
  };
  const closeCommunicationModal = () => {
    setopenCommunication(false);
    setMessage("");
    // setisGetMessage(false);
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleSubmit = () => {
    setloading(true);
    const myHeaders = new Headers();
    // myHeaders.append("Authorization", `Bearer ${token}`);

    const formData = new FormData();
    messagedata?.service?.id &&
      formData.append("service_id", messagedata?.service?.id);
    formData.append("sub_service_id", messagedata?.sub_service?.id);
    formData.append("customer_service_id", customerid);
    formData.append("message", message);

    fetch(api.send_service_message, {
      headers: myHeaders,
      body: formData,
      method: "POST",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setSnackbarOpen(true);
          setSnackbarMessage(result.message);
          setSnackbarSeverity("success");
          closeCommunicationModal();
          handleMessage();
          setTimeout(() => {
            handleSnackbarClose();
          }, 2000);
        } else {
          setSnackbarOpen(true);
          setSnackbarMessage(result.message);
          setSnackbarSeverity("error");
          setTimeout(() => {
            handleSnackbarClose();
          }, 2000);
        }
        setloading(false);
      })
      .catch((error) => {
        console.log("Fetch error:", error);
        setloading(false);
      })
      .finally(() => setloading(false));
  };

  return (
    <Box>
      <Stack
        sx={{
          flexDirection: "row",
          width: "100%",
          paddingTop: 1,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            flex: 1,
            marginLeft: 10,
            color: "#f90000",
          }}
        >
          Message
        </Typography>
        <Button
          disabled={
            refresh || messagedata.tracking_status === "completed"
              ? true
              : false
          }
          variant="contained"
          color="secondary"
          sx={{
            textTransform: "none",
            backgroundColor: "#f90000",
            color: "white",
            alignItems: "flex-end",
            fontSize: 12,
            marginRight: 0.5,
            textAlign: "center",
            "&:hover": {
              backgroundColor: "#ccc",
              boxShadow: "none",
            },
          }}
          onClick={() => {
            setopenCommunication(true);
          }}
        >
          Add Message
        </Button>
      </Stack>

      <Box
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          height: "424px",
          overflowY: "auto", // Enables vertical scrolling only
          overflowX: "hidden", // Hides horizontal scrollbar
          scrollbarWidth: "thin", // For Firefox, makes the scrollbar thinner
          scrollbarColor: "#888 #f1f1f1", // Customize scrollbar color for Firefox
        }}
      >
        {data.map((message) => (
          <Box
            key={message.id}
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-start",
              //   message.sender === "user" ? "flex-end" : "flex-start",
              mb: 1,
              paddingLeft: 1,
              mt: 2,
            }}
          >
            <Box
              sx={{
                // maxWidth: "85%",
                padding: 2,
                // paddingBottom: 2,
                marginBottom: 1,
                backgroundColor: "#fff",
                borderRadius: 2,
                boxShadow: 1,
                alignItems: "center",
                marginLeft: message.user_id === 0 ? "auto" : 0,
                marginRight: message.user_id === 0 ? 1 : "auto",
                backgroundColor:
                  message.user_id === 0 ? "#DCF8C6" : "rgba(0, 0, 0, 0.05)",
              }}
            >
              <Typography variant="body2" color="text.primary">
                {message.message}
              </Typography>{" "}
              <Typography
                pt={0.5}
                variant="body2"
                color="text.primary"
                sx={{ textAlign: "right", fontSize: 11 }}
              >
                {message.message_time}
              </Typography>
            </Box>
          </Box>
        ))}

        {data?.length === 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90%",
            }}
          >
            <Typography color={"#ccc"}>{error}</Typography>
          </Box>
        )}
      </Box>

      <Modal
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
        open={openCommunication}
        onClose={closeCommunicationModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            maxWidth: "60%",
            maxHeight: "90%",
            bgcolor: "background.paper",
            boxShadow: 24,
            overflow: "auto",
            p: 1,
            border: "2px solid",
            borderColor: "#f90000",
            flex: 1,
          }}
          className="admin_modal_pa"
        >
          <Box
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              p: 2,
            }}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              pb={1}
              alignItems="center"
            >
              <Typography
                variant="h6"
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  color: "#f90001",
                }}
              >
                Send Message
              </Typography>
              <IconButton onClick={closeCommunicationModal} edge="end">
                <CloseIcon />
              </IconButton>
            </Box>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Message"
              variant="outlined"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#f90000", // Default red border
                  },
                  "&:hover fieldset": {
                    borderColor: "#f90000", // Red border on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#f90000", // Red border when focused
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "red", // Default red color for placeholder
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "red", // Red color when placeholder is active (above the input)
                },
              }}
            />
            <Grid
              mb={-2}
              xs={12}
              display={"flex"}
              justifyContent={"flex-end"}
              mt={2}
            >
              <LoadingButton
                variant="contained"
                color="secondary"
                onClick={handleSubmit}
                loading={loading}
                sx={{
                  textTransform: "none",
                  backgroundColor: "#f90000",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#ccc",
                    boxShadow: "none",
                  },
                }}
              >
                {"Send"}
              </LoadingButton>
            </Grid>
          </Box>
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
