import {
  Alert,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import api from "../Constants/Api";
import { LoadingButton } from "@mui/lab";
export default function AddDocumentModal({
  onClose,
  getdata,
  adddocid,
  refresh,
  customerid,
  data,
}) {
  const [file, setFile] = useState([]);
  const [showSnackbar, setshowSnackbar] = useState(false);
  const [message, setmessage] = useState("");
  const [mode, setmode] = useState("error");
  const [loading, setloading] = useState(false);
  const [disable, setdisable] = useState(false);
  const [roleList, setroleList] = useState([
    { id: 333, name: "All" },
    { id: 1, name: "User" },
    { id: 2, name: "Admin" },
  ]);
  const [role, setrole] = useState("");
  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFile((prevFiles) => {
      if (!Array.isArray(prevFiles)) {
        return { ...newFiles }; // Initialize state with new files if it's not an array
      }
      return [...prevFiles, ...newFiles]; // Append new files to existing state
    });
  };

  const handleRemoveFile = (index) => {
    setFile((prev) => prev.filter((_, i) => i !== index));
  };
  const handleFileDoc = () => {
    setloading(true);
    const formdata = new FormData();
    formdata.append("customer_service_id", adddocid);
    file.map((value, index) => {
      formdata.append(`file`, value);
    });
    role && formdata.append("step_id", role.id);
    const requestOptions = {
      method: "POST",
      redirect: "follow",
      body: formdata,
    };
    fetch(api.save_service_document_for_web, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          setshowSnackbar(true);
          setmessage(result?.message);
          setmode("success");
          refresh();
          setTimeout(() => {
            getdata();
          }, 2000); // 2000 milliseconds = 2 seconds
          setloading(false);

          setloading(false);
        } else {
          console.log("error", result.message);
          setdisable(false);
          setmessage(result?.message);
          setmode("error");
          setshowSnackbar(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setloading(false);
        setdisable(false);
      });
  };

  useEffect(() => {
    if (customerid) {
      handleGetSteps();
    }
  }, []);

  const handleGetSteps = () => {
    const myHeaders = new Headers();
    // myHeaders.append("Authorization", `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append("customer_service_id", customerid);
    formdata.append("sub_service_id", data?.sub_service?.id);
    formdata.append("service_id", data?.service?.id);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
    };
    fetch(api.get_steps_for_web, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.success) {
          // localStorage.setItem('stepsData', JSON.stringify(result?.data));
          setroleList(result?.data);
        }
      })
      .catch((error) => {
        setloading(false);
        console.log(error);
      })
      .finally(() => {});
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom color={"#f90000"}>
        Add Document
      </Typography>

      <Box
        sx={{
          border: "2px dashed #f90000",
          padding: 2,
          borderRadius: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 100,
          marginBottom: 2,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Typography>{file?.name}</Typography>
        </Stack>

        <Button
          disabled={disable || file.length == 1}
          variant="contained"
          component="label"
          startIcon={<CloudUploadIcon />}
          sx={{
            textTransform: "none",
            fontWeight: "bold",
            //   color: "#f90000",
            backgroundColor: "#f90000",
            "&:hover": {
              backgroundColor: "#ccc",
              boxShadow: "none",
            },
          }}
        >
          Add Document
          <input
            type="file"
            onChange={handleFileChange}
            id="file-input-bottom"
            // multiple
            style={{ display: "none" }}
          />
        </Button>
      </Box>

      <Box>
        <TextField
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: role ? "#f90000" : "#ccc", // Active focus border color
              },
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#f90000", // Hover border color
            },
          }}
          InputLabelProps={{
            sx: {
              color: role ? "#f90000" : "#ccc", // Placeholder color based on value
              "&.Mui-focused": {
                color: role ? "#f90000" : "#ccc", // Placeholder color on focus
              },
            },
          }}
          style={{
            paddingBottom: 5,
            // width: '40%'
            // marginLeft: '2.5%'
          }}
          select
          value={role}
          label={"Select Step"}
          onChange={(e) => {
            setrole(e.target.value);
          }}
          className="width_full_status"
        >
          {roleList.map((option) => (
            <MenuItem key={option.id} value={option}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Box>
        <List>
          {file &&
            file.map((doc, index) => (
              <>
                <ListItem
                  key={index}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleRemoveFile(index)}
                      disabled={disable}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={doc.name}
                    // secondary={`Uploaded at: ${doc.lastModifiedDate}`}
                  />
                </ListItem>
              </>
            ))}
        </List>
      </Box>
      <Stack
        sx={{
          flex: "display",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          flexDirection: "row",
          gap: 1,
        }}
      >
        <Button
          variant="contained"
          // color="secondary"
          disabled={disable}
          sx={{
            textTransform: "none",
            backgroundColor: "#f90000",
            color: "white",
            "&:hover": {
              backgroundColor: "#ccc",
              boxShadow: "none",
            },
          }}
          onClick={onClose}
        >
          Cancel
        </Button>

        <LoadingButton
          disabled={file.length !== 0 ? false : true}
          loading={loading}
          variant="contained"
          color="secondary"
          sx={{
            textTransform: "none",
            backgroundColor: "#f90000",
            color: "white",
            "&:hover": {
              backgroundColor: "#ccc",
              boxShadow: "none",
            },
          }}
          onClick={() => {
            handleFileDoc();
            setdisable(true);
          }}
        >
          Upload File
        </LoadingButton>
      </Stack>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => {
          setshowSnackbar(false);
        }}
      >
        <Alert
          onClose={() => {
            setshowSnackbar(false);
          }}
          severity={mode}
          sx={{
            width: "100%",
            color: "black",
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
