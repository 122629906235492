import React, { useEffect, useState } from "react";
import {
  CssBaseline,
  Avatar,
  useTheme,
  useMediaQuery,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import Maps from "./Components/Maps";
import CustomTimeline from "./Components/CustomTimeline";
import { useLocation } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import BusinessIcon from "@mui/icons-material/Business";
import LanguageIcon from "@mui/icons-material/Language";
import ArticleIcon from "@mui/icons-material/Article";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment";
import lottie_files from "./assets/icons/red.json";
import PinDropIcon from "@mui/icons-material/PinDrop";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { AppBar, Toolbar, Typography, Grid, Box, Paper } from "@mui/material";
import Lottie from "lottie-react";
import OtpVerification from "./pages/OtpVerification";
import Messagebox from "./Components/Messagebox";
import ListDocument from "./Components/ListDocument";
import { LoadingButton } from "@mui/lab";
import OTPInput from "react-otp-input";
import api from "./Constants/Api";
import RefreshIcon from "@mui/icons-material/Refresh";
import Iconify from "./Components/Iconify";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function App() {
  const [data, setData] = useState();
  const [verification, setverification] = useState(false);
  const query = useQuery();
  const id = query.get("id");
  const [customerserviceid, setcustomerserviceid] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [otp, setOtp] = useState("");
  const [loading, setloading] = useState(false);
  const [showSnackbar, setshowSnackbar] = useState(false);
  const [message, setmessage] = useState("");
  const [mode, setmode] = useState("error");
  const [customerid, setcustomerid] = useState("");
  const [isrefresh, setisrefresh] = useState(false);
  const [refreshloader, setrefreshloader] = useState(false);
  const [activeInput, setActiveInput] = useState(null);

  useEffect(() => {
    get_service_detail_for_customer(id);
  }, [id]);

  function get_service_detail_for_customer(id) {
    setrefreshloader(true);
    const params = new URLSearchParams();
    params.append("id", id);

    const requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    fetch(
      `https://monprogress.samtwireless.com/public/api/get_service_detail_for_customer?id=${id}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          setData(result.data);
          setcustomerserviceid(result?.customer_service_id);
          setcustomerid(result?.customer_service_id);
        } else {
          setverification(true);
          console.log("error ", result.message);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setrefreshloader(false);
        setisrefresh(false);
      });
  }

  const handleVerification = () => {
    setloading(true);
    const formdata = new FormData();
    formdata.append("id", id);
    formdata.append("code", otp);
    const requestOptions = {
      method: "POST",
      redirect: "follow",
      body: formdata,
    };
    fetch(api.verify_web_otp, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          setshowSnackbar(true);
          setmessage(result?.message);
          setmode("success");
          setIsActive(true);
          get_service_detail_for_customer(id);
        } else {
          console.log("error", result.message);
          setshowSnackbar(true);
          setmessage(result?.message);
          setmode("error");
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setloading(false);
      });
  };

  const otpContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    width: "50%",
  };

  const otpInputStyle = {
    width: "60%",
    height: "56px",
    textAlign: "center",
    fontSize: "18px",
    borderRadius: "4px",
    boxSizing: "border-box",
    padding: "6px",
    borderWidth: "1.5px",
    borderColor: "#f90000",
    borderStyle: "solid",
  };

  const handleFocus = (index) => {
    setActiveInput(index);
  };

  const handleBlur = () => {
    setActiveInput(null);
  };
  return (
    <div style={{}}>
      {data && isActive == false ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            gap: 2, // Space between elements
            padding: 2,
            overflow: "hidden",
          }}
        >
          <img
            src={require("../src/assets/icons/logtrans.png")} // Replace this with the actual path to your logo
            alt="Logo"
            style={{ width: "200px", marginBottom: "25px" }} // Adjust size and margin as needed
          />

          <Typography
            className="heading_center"
            variant="h4"
            gutterBottom
            sx={{ color: "#F90000" }}
          >
            Please check your email for OTP
            {/* {`An OTP has been sent to ${email}. Please check your inbox to verify your account`} */}
          </Typography>
          <div
            style={{
              ...otpContainerStyle,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <OTPInput
              numInputs={5}
              value={otp}
              onChange={setOtp}
              renderSeparator={<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
              renderInput={(props, index) => (
                <input
                  {...props}
                  style={otpInputStyle}
                  onFocus={() => handleFocus(index)}
                  onBlur={handleBlur}
                />
              )}
            />
          </div>
          <LoadingButton
            style={{ backgroundColor: "#f90001", width: "50%" }}
            onClick={handleVerification}
            fullWidth
            size="medium"
            variant="contained"
            loading={loading}
          >
            Continue
          </LoadingButton>
          <Box
            sx={{
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              mt: 8,
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "bold",
                color: "#000",
                // marginBottom: 1
              }}
            >
              If you have any issues, please contact us at:
            </Typography>
            <Typography
              component="a"
              href="mailto:info@samtwireless.com"
              sx={{
                fontSize: "14px",
                color: "#f90001",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              info@samtwireless.com
            </Typography>
          </Box>
        </Box>
      ) : (
        <>
          {data ? (
            <>
              {data && data?.status === "completed" ? (
                <Box
                  sx={{
                    position: "fixed", // To make it overlay on the entire screen
                    top: 0,
                    left: 0,
                    width: "100%", // Full viewport width
                    height: "90%", // Full viewport height
                    display: "flex",
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                    backgroundColor: "#ffffff", // Optional: Add a light overlay background
                    zIndex: 9999, // Ensure it appears above other elements
                    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
                    overflow: "hidden",
                    overflowY: "hidden",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#f90000",
                      fontWeight: "bold",
                      marginBottom: "16px",
                      textTransform: "uppercase",
                    }}
                  >
                    Service are no longer available
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <AppBar position="static">
                    <Toolbar
                      sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        padding: 1,
                        backgroundColor: "#f90001",
                        overflowX: "auto",
                      }}
                    >
                      <Avatar
                        src={data?.user?.avatar + "?v=" + Date.now()}
                        alt={data?.user?.name}
                        style={{
                          marginRight: "16px",
                          width: 45,
                          height: 45,
                          borderRadius: 100,
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          width: "100%",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            flexGrow: 1,
                            fontSize: {
                              xs: "1rem",
                              sm: "0.875rem",
                              md: "1.4rem",
                              textTransform: "capitalize",
                            },
                          }}
                        >
                          <PersonIcon
                            style={{ marginBottom: -3, marginLeft: -3 }}
                          />{" "}
                          {data?.customer_name || "N/A"}
                        </Typography>

                        <Grid
                          container
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Grid container sx={{ flex: 1, flexWrap: "wrap" }}>
                            <Grid item xs={6} sm={3}>
                              <Typography
                                noWrap
                                variant="body2"
                                sx={{
                                  width: { xs: 130, sm: 160, md: 250 },
                                  fontSize: {
                                    xs: "0.55rem",
                                    sm: "0.575rem",
                                    md: "0.8rem",
                                  },
                                }}
                              >
                                <EmailIcon
                                  style={{ fontSize: 14, marginBottom: -3 }}
                                />{" "}
                                {data?.email || "N/A"}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <Typography
                                noWrap
                                variant="body2"
                                sx={{
                                  width: { xs: 100, sm: 160, md: 250 },
                                  fontSize: {
                                    xs: "0.55rem",
                                    sm: "0.575rem",
                                    md: "0.8rem",
                                    textTransform: "capitalize",
                                  },
                                }}
                              >
                                <CallIcon
                                  style={{ fontSize: 14, marginBottom: -3 }}
                                />{" "}
                                {data?.contact || "N/A"}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <Typography
                                noWrap
                                variant="body2"
                                sx={{
                                  width: { xs: 130, sm: 160, md: 250 },
                                  fontSize: {
                                    xs: "0.55rem",
                                    sm: "0.575rem",
                                    md: "0.8rem",
                                    textTransform: "capitalize",
                                  },
                                }}
                              >
                                <BusinessIcon
                                  style={{ fontSize: 14, marginBottom: -3 }}
                                />{" "}
                                {data?.user?.name || "N/A"}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              {/* {data?.user?.website_link && ( */}
                              <Typography
                                noWrap
                                variant="body2"
                                sx={{
                                  width: { xs: 150, sm: 160, md: 250 },
                                  fontSize: {
                                    xs: "0.55rem",
                                    sm: "0.575rem",
                                    md: "0.8rem",
                                  },
                                }}
                              >
                                <LanguageIcon
                                  style={{ fontSize: 14, marginBottom: -3 }}
                                />{" "}
                                {data?.user?.website_link || "N/A"}
                              </Typography>
                              {/* )} */}
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <Typography
                                noWrap
                                variant="body2"
                                sx={{
                                  width: { xs: 80, sm: 160, md: 250 },
                                  fontSize: {
                                    xs: "0.55rem",
                                    sm: "0.575rem",
                                    md: "0.8rem",
                                    textTransform: "capitalize",
                                  },
                                }}
                              >
                                <ArticleIcon
                                  style={{ fontSize: 14, marginBottom: -3 }}
                                />{" "}
                                {data?.tracking_status || "N/A"}
                              </Typography>
                            </Grid>

                            <Grid item xs={6} sm={3}>
                              <Typography
                                noWrap
                                variant="body2"
                                sx={{
                                  width: { xs: 130, sm: 160, md: 250 },
                                  fontSize: {
                                    xs: "0.55rem",
                                    sm: "0.575rem",
                                    md: "0.8rem",
                                    textTransform: "capitalize",
                                  },
                                }}
                              >
                                <CalendarMonthIcon
                                  style={{ fontSize: 14, marginBottom: -3 }}
                                />{" "}
                                {moment(data?.updated_at).format(
                                  "DD-MMM-YYYY, h:mm A"
                                ) || "N/A"}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <Typography
                                noWrap
                                variant="body2"
                                sx={{
                                  width: { xs: 250, sm: 160, md: 250 },
                                  fontSize: {
                                    xs: "0.55rem",
                                    sm: "0.575rem",
                                    md: "0.8rem",
                                    textTransform: "capitalize",
                                  },
                                }}
                              >
                                <PinDropIcon
                                  style={{ fontSize: 14, marginBottom: -3 }}
                                />{" "}
                                {data?.user?.address || "N/A"}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <Typography
                                noWrap
                                variant="body2"
                                sx={{
                                  display: "flex",
                                  width: { xs: 250, sm: 160, md: 250 },
                                  fontSize: {
                                    xs: "0.55rem",
                                    sm: "0.575rem",
                                    md: "0.8rem",
                                    textTransform: "capitalize",
                                  },
                                }}
                              >
                                <Iconify
                                  icon={"ic:outline-miscellaneous-services"}
                                  sx={{ fontSize: 17, marginRight: 0.3 }}
                                />
                                {/* <LocalShippingIcon
                                  style={{ fontSize: 14, marginBottom: -3 }}
                                />{" "} */}
                                {data?.sub_service?.name || "N/A"}
                              </Typography>
                            </Grid>
                          </Grid>
                          <LoadingButton
                            variant="contained"
                            style={{
                              height: 25,
                              fontSize: 10,
                              fontWeight: "600",
                              borderRadius: 5,
                              // width: 50,
                              // backgroundColor: "#f90000",
                              backgroundColor: "#fff",
                              color: "#f90000",
                              display: "flex",
                              justifyContent: "space-between",
                              paddingLeft: 9,
                              paddingRight: 9,
                            }}
                            onClick={() => {
                              setrefreshloader(true);
                              // setData([]);
                              setloading(true);
                              setisrefresh(true);
                              get_service_detail_for_customer(id);
                            }}
                          >
                            <RefreshIcon
                              // icon="line-md:rotate-270"
                              style={{
                                fontSize: 14,
                                color: "#f90000",
                                height: 15,
                                width: 15,
                              }}
                            />
                            Refresh
                          </LoadingButton>
                        </Grid>
                      </Box>
                    </Toolbar>
                  </AppBar>

                  <Grid
                    container
                    sx={{
                      padding: 2,
                    }}
                  >
                    {data?.pickup_lat &&
                      data?.pickup_lon &&
                      data?.dropoff_lat &&
                      data?.dropoff_lon && (
                        <Grid item xs={12} sm={11.9} lg={8}>
                          <Box sx={{ height: "465px" }}>
                            {" "}
                            {/* Ensure the container has a height */}
                            <Maps data={data} />
                          </Box>
                        </Grid>
                      )}
                    <Grid
                      item
                      xs={12}
                      sm={11.9}
                      lg={
                        data?.pickup_lat &&
                        data?.pickup_lon &&
                        data?.dropoff_lat &&
                        data?.dropoff_lon
                          ? 4
                          : 12
                      }
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", md: "row" },
                          gap: 2,
                        }}
                      >
                        <Paper
                          elevation={3}
                          style={{
                            flex: 1,
                            height: "465px",
                            overflow: "hidden",
                          }}
                        >
                          {data && <CustomTimeline data={data} />}
                        </Paper>

                        {data?.service?.is_pickup !== 1 && (
                          <>
                            <Paper
                              elevation={3}
                              style={{
                                flex: 1,
                                height: "465px",
                                overflow: "hidden",
                              }}
                            >
                              {data && (
                                <Messagebox
                                  messaegeid={customerserviceid}
                                  messagedata={data}
                                  customerid={customerid}
                                  refresh={isrefresh}
                                  setrefresh={setisrefresh}
                                />
                              )}
                            </Paper>

                            <Paper
                              elevation={3}
                              style={{
                                flex: 1,
                                height: "465px",
                                overflow: "hidden",
                              }}
                            >
                              {data && (
                                <ListDocument
                                  data={data}
                                  customerid={id}
                                  docid={customerserviceid}
                                  refresh={isrefresh}
                                  setrefresh={setisrefresh}
                                />
                              )}
                            </Paper>
                          </>
                        )}
                      </Box>
                    </Grid>
                  </Grid>

                  {refreshloader && (
                    <Grid
                      container
                      spacing={2}
                      sx={{ padding: 2 }}
                      style={{
                        height: "200vh",
                        width: "200vw",
                        backgroundColor: "rgba(0,0,0,0.01)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          height: 400,
                          width: 650,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Lottie animationData={lottie_files} />
                      </div>
                      {/* <h2 style={{ color: "#f90001" }}>Please wait...</h2> */}
                    </Grid>
                  )}
                </Box>
              )}
            </>
          ) : (
            <Grid
              container
              spacing={2}
              sx={{ padding: 2 }}
              style={{
                height: "100vh",
                width: "100vw",
                backgroundColor: "rgba(0,0,0,0.01)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  height: 120,
                  width: 350,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Lottie animationData={lottie_files} />
              </div>
              {/* <h2 style={{ color: "#f90001" }}>Please wait...</h2> */}
            </Grid>
          )}
        </>
      )}

      {data && data.status === "completed" && (
        <Box
          sx={{
            position: "fixed", // To make it overlay on the entire screen
            top: 0,
            left: 0,
            width: "100%", // Full viewport width
            height: "100%", // Full viewport height
            display: "flex",
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            backgroundColor: "#ffffff", // Optional: Add a light overlay background
            zIndex: 9999, // Ensure it appears above other elements
            boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "#f90000",
              fontWeight: "bold",
              marginBottom: "16px",
              textTransform: "uppercase",
            }}
          >
            Service are no longer available
          </Typography>
        </Box>
      )}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => {
          setshowSnackbar(false);
        }}
      >
        <Alert
          onClose={() => {
            setshowSnackbar(false);
          }}
          severity={mode}
          sx={{
            width: "100%",
            color: "black",
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default App;
